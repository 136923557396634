define("cms-frontend/utils/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TrackingEvent = _exports.Constants = _exports.CookieName = void 0;
  var CookieName;
  _exports.CookieName = CookieName;

  (function (CookieName) {
    CookieName["HIDE_COOKIE_BANNER"] = "hide-cookies-banner";
    CookieName["BANNER_TRACKING_ANALYTICS"] = "tracking-analytics";
    CookieName["BANNER_TRACKING_MARKETING"] = "tracking-marketing";
    CookieName["BANNER_TRACKING_SET_AT"] = "tracking-set-at";
  })(CookieName || (_exports.CookieName = CookieName = {}));

  var Constants;
  _exports.Constants = Constants;

  (function (Constants) {
    Constants["CONFIG_COOKIE_BANNER_ENABLED"] = "cookieBanner";
    Constants[Constants["COOKIE_TRACKING_TIME_IN_DAYS"] = 730] = "COOKIE_TRACKING_TIME_IN_DAYS";
  })(Constants || (_exports.Constants = Constants = {}));

  var TrackingEvent;
  _exports.TrackingEvent = TrackingEvent;

  (function (TrackingEvent) {
    TrackingEvent["COOKIE_ACCEPT_UNCOLLAPSED"] = "customer/tracking/accept=uncollapsed";
    TrackingEvent["COOKIE_ACCEPT_COLLAPSED"] = "customer/tracking/accept=collapsed";
    TrackingEvent["COOKIE_CLOSE"] = "customer/tracking/accept=close";
    TrackingEvent["BANNER_LOADED"] = "customer/tracking/load:initial";
    TrackingEvent["COOKIE_MORE_INFO"] = "customer/tracking/information=visit";
    TrackingEvent["COOKIE_MARKETING_ACTIVATE"] = "customer/tracking/marketing=activate";
    TrackingEvent["COOKIE_MARKETING_DEACTIVATE"] = "customer/tracking/marketing=deactivate";
  })(TrackingEvent || (_exports.TrackingEvent = TrackingEvent = {}));
});