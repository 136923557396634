define("cms-frontend/instance-initializers/capture-link", ["exports", "build-content/valid-urls"], function (_exports, _validUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable no-param-reassign */
  function closestLink(element) {
    if (element.closest) {
      return element.closest('a');
    }

    element = element.parentElement;

    while (element && element.tagName !== 'A') {
      element = element.parentElement;
    }

    return element;
  }
  /* eslint-enable no-param-reassign */


  var _default = {
    name: 'capture-link',
    initialize: function initialize(applicationInstance) {
      // we only want this to run in the browser, not in fastboot
      if (typeof FastBoot === 'undefined') {
        var hrefToClickHandler = function _hrefToClickHandler(event) {
          var target = event.target;
          var link = target.tagName === 'A' ? target : closestLink(target);

          if (link) {
            var hrefAttribute = link.attributes.href;
            var url = hrefAttribute && hrefAttribute.value;

            if (url) {
              url = url.charAt(url.length - 1) === '/' ? url.slice(0, -1) : url;

              if (!_validUrls.default.routes.includes(url)) {
                window.location.assign(url);
              }
            }
          }
        };

        document.body.addEventListener('click', hrefToClickHandler); // Teardown on app destruction: clean up the event listener to avoid
        // memory leaks.

        applicationInstance.reopen({
          willDestroy: function willDestroy() {
            document.body.removeEventListener('click', hrefToClickHandler); // eslint-disable-next-line prefer-rest-params

            return this._super.apply(this, arguments);
          }
        });
      }
    }
  };
  _exports.default = _default;
});