define("cms-frontend/preview/entry/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XkGOfL45",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[4,\"if\",[[23,1,[\"entry\"]]],null,{\"statements\":[[0,\"  \"],[5,\"preview/entry-preview-screen\",[],[[\"@entryId\",\"@entry\"],[[23,1,[\"entryId\"]],[23,1,[\"entry\"]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"preview/error-screen\",[],[[\"@entryId\"],[[23,1,[\"entryId\"]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cms-frontend/preview/entry/template.hbs"
    }
  });

  _exports.default = _default;
});