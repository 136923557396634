define("cms-frontend/components/wrapper-components/breadcrumbs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cV/TZfhS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[28,\"grid\",[\"container\"],null]],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[28,\"grid\",[\"row justify-content-center\"],null]],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[28,\"grid\",[\"col-md-8 col-12\"],null]],[8],[0,\"\\n      \"],[5,\"breadcrumbs\",[],[[],[]]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/breadcrumbs/template.hbs"
    }
  });

  _exports.default = _default;
});