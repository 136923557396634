define("cms-frontend/translations/de-at", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "404": {
      "cta": "zur Startseite",
      "title": "Sorry! Wir konnten die gesuchte Seite nicht finden."
    },
    "breadcrumbs": {
      "accessibility_label": "Breadcrumbs",
      "root_label": "Clark"
    },
    "button": {
      "spinner_accessibility_label": "Wird geladen"
    },
    "carousel": {
      "navigation": {
        "next": "Nächste Ansicht",
        "previous": "Vorherige Ansicht"
      }
    },
    "common": {
      "new_window_accessibility_label": "(öffnet ein neues Fenster)"
    },
    "cookie_banner": {
      "description": "Wir verwenden Cookies, um Clark-Nutzern die bestmögliche Browsing-Erfahrung zu bieten. Wähle ”Akzeptieren”, um alle gewählten Arten von Cookies zu akzeptieren.",
      "description_collapsed": "Wir verwenden Cookies, um Clark-Nutzern die bestmögliche Browsing-Erfahrung zu bieten. Die gesammelten Daten werden zur Optimierung der Website und App genutzt, um deine Erfahrung zu optimieren und personalisieren. Die Daten werden im Rahmen unserer EU-weiten Tätigkeit genutzt. Wähle ”Akzeptieren”, um alle Cookies zu akzeptieren.",
      "error": "Bei der Verarbeitung Ihrer Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
      "header": "Wir verwenden Cookies",
      "more_info_link": "/datenschutz",
      "more_info_text": "Mehr erfahren",
      "primary_cta": "Akzeptieren",
      "secondary_cta": "Konfigurieren",
      "tracking": {
        "analytics": {
          "description": "Diese Cookies sind notwendig, um dir eine sichere und funktionale Nutzung von Clark zu ermöglichen. Du kannst sie nicht de-aktivieren.",
          "header": "Funktionale Cookies"
        },
        "marketing": {
          "description": "Diese Cookies helfen uns zu verstehen, wie effektiv unsere Marketingkampagnen sind und helfen uns, Clark für dich zu optimieren. Wir übermitteln dazu pseudonymisierte Daten an unsere Marketingpartner.",
          "header": "Tracking & Marketing"
        }
      }
    },
    "footer": {
      "authorization": {
        "approval": "durch die WKO",
        "title": "Zugelassen"
      },
      "companies": {
        "about_us": "Über uns",
        "career": "Karriere",
        "press": "Presse",
        "title": "Unternehmen"
      },
      "contact": {
        "email": "service@goclark.at",
        "phone": "01 3860870",
        "timings": "(Mo. - Fr. 08 - 19 Uhr)",
        "title": "Kontakt"
      },
      "copyright": {
        "conditions": "AGB",
        "data_protection": "Datenschutz",
        "imprint": "Impressum",
        "initial_information": "Erstinformation",
        "title": "&#169; Clark GMBH 2019"
      },
      "datacenter": {
        "location": "in Deutschland",
        "title": "Rechenzentrum"
      },
      "information": {
        "function": "So funktioniert’s",
        "magazine": "CLARK Magazine",
        "partner": "Partner",
        "title": "Informationen"
      }
    },
    "forms": {
      "email": {
        "error_message": "Ungültige E-Mailadresse"
      }
    },
    "header": {
      "cta": {
        "login": "Einloggen",
        "mobile_install": "Installieren",
        "my_account": "Mein Konto",
        "start": "Jetzt starten"
      },
      "links": {
        "how": "So funktioniert’s",
        "insurances": {
          "subMenu": {
            "health": {
              "links": {
                "disability": "Berufsunfähigkeitsversicherung",
                "private": "Private Krankenzusatzversicherung"
              },
              "title": "Gesundheit & Existenz"
            },
            "pension": {
              "links": {
                "company_scheme": "Betriebliche Altersvorsorge",
                "private_scheme": "Private Altersvorsorge & Vermögensaufbau",
                "risky_scheme": "Risikolebensversicherung"
              },
              "title": "Altersvorsorge"
            },
            "property": {
              "links": {
                "car": "Kfz-Versicherung",
                "house": "Haushaltsversicherung",
                "law": "Rechtsschutzversicherung"
              },
              "title": "Besitz\t& Eigentum"
            }
          },
          "title": "Versicherungen"
        }
      }
    },
    "lead_gen": {
      "terms_and_condition": "Ich bin damit einverstanden, dass CLARK mich per E-mail und zur Klärung von Rückfragen telefonisch kontaktiert. Es gelten unsere Bestimmungen für den Datenschutz."
    },
    "name": "Clark",
    "next-steps": {
      "title": "Nächste Schritte"
    },
    "partners": {
      "partner_of": "Partner von",
      "partner_of_label": "Partner von {name}"
    },
    "preview": {
      "error-screen": {
        "hint": "Stelle sicher, dass du eine valide ID für einen existierenden Eintrag verwendest.",
        "message": "Konnte keine Vorschau für den angeforderten Eintrag generieren"
      },
      "not-found-screen": {
        "message": "Hoppla! Sorry, hierfür gibt’s leider keine Vorschau"
      }
    },
    "skepticsSlider": {
      "next": "Weiter",
      "play": "Video zu {name} anzeigen"
    },
    "slideShow": {
      "navigation": {
        "control": "Gehe zu Ansicht {slideNumber}"
      }
    }
  };
  _exports.default = _default;
});