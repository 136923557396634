define("cms-frontend/services/api", ["exports", "@clark/cms-api/services/api"], function (_exports, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _api.default;
    }
  });
});