define("cms-frontend/services/valid-routes", ["exports", "@clark/cms-ui/services/valid-routes"], function (_exports, _validRoutes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _validRoutes.default;
    }
  });
});