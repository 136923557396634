define("cms-frontend/utils/sentry", ["exports", "@sentry/browser", "@sentry/integrations/esm", "cms-frontend/config/environment", "cms-frontend/utils/sentry-error-filter"], function (_exports, Sentry, _esm, _environment, _sentryErrorFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initSentry = initSentry;
  _exports.captureException = captureException;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var FILTERS = [/^Test/];

  function initSentry() {
    try {
      Sentry.init(_objectSpread(_objectSpread({}, _environment.default.sentry), {}, {
        release: _environment.default.APP.version,
        integrations: [new _esm.Ember(), new Sentry.Integrations.Breadcrumbs({
          console: false
        }), new Sentry.Integrations.GlobalHandlers({
          onerror: true,
          onunhandledrejection: true
        })],
        beforeSend: (0, _sentryErrorFilter.filterFactory)(FILTERS)
      }));
    } catch (error) {
      console.error(error);
    }
  }

  function captureException(error, message) {
    Sentry.withScope(function (scope) {
      scope.setExtras({
        message: message
      });
      Sentry.captureException(error);
    });
  }
});