define("cms-frontend/components/wrapper-components/rich-text/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "header-height": "80px",
    "up-to-medium": "only screen and (max-width: calc(769px - 1px))",
    "seo-body-link": "#0439D7",
    "rich-text-img": "_rich-text-img_1whlvn",
    "rich-text-header-anchor": "_rich-text-header-anchor_1whlvn",
    "rich-text-unordered-list": "_rich-text-unordered-list_1whlvn",
    "embedded-module-wrapper": "_embedded-module-wrapper_1whlvn",
    "rich-text-container": "_rich-text-container_1whlvn",
    "empty-paragraph": "_empty-paragraph_1whlvn",
    "line-breaker": "_line-breaker_1whlvn"
  };
  _exports.default = _default;
});