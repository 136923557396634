define("cms-frontend/components/wrapper-components/rich-text/anchor-headline/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0S2JpNRu",
    "block": "{\"symbols\":[\"link\",\"@module\"],\"statements\":[[7,\"div\",true],[11,\"class\",[28,\"grid\",[\"row\"],null]],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"grid\",[\"col-12\"],null],\" \",[28,\"local-class\",[\"anchor-link-container\"],[[\"from\"],[\"cms-frontend/components/wrapper-components/rich-text/anchor-headline/styles\"]]]]]],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"data-test-rich-text-anchor-headline-title\",\"\"],[8],[0,\"\\n      \"],[1,[28,\"markdown-to-html\",null,[[\"markdown\",\"class\"],[[28,\"transform-rich-text-header\",[[23,2,[\"heading\"]]],null],[28,\"concat\",[[28,\"typo\",[\"h3\"],null],\" \",[28,\"local-class\",[\"anchor-link-title\"],[[\"from\"],[\"cms-frontend/components/wrapper-components/rich-text/anchor-headline/styles\"]]]],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"ul\",true],[11,\"class\",[29,[[28,\"local-class\",[\"anchor-link-list\"],[[\"from\"],[\"cms-frontend/components/wrapper-components/rich-text/anchor-headline/styles\"]]]]]],[10,\"data-test-rich-text-anchor-headline-link-list\",\"\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"headerTags\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\",true],[8],[0,\"\\n          \"],[5,\"link\",[[12,\"class\",[29,[[28,\"local-class\",[\"anchor-link-list-link\"],[[\"from\"],[\"cms-frontend/components/wrapper-components/rich-text/anchor-headline/styles\"]]]]]]],[[\"@url\"],[[29,[\"#\",[23,1,[\"id\"]]]]]],{\"statements\":[[1,[23,1,[\"text\"]],false]],\"parameters\":[]}],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/rich-text/anchor-headline/template.hbs"
    }
  });

  _exports.default = _default;
});