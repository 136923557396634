define("cms-frontend/content/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "section-wrapper-container": "_section-wrapper-container_1hnsed"
  };
  _exports.default = _default;
});