define("cms-frontend/components/wrapper-components/success-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G0hFdwlP",
    "block": "{\"symbols\":[\"button\",\"imgSrc\",\"@module\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"success-module-wrapper\"],[[\"from\"],[\"cms-frontend/components/wrapper-components/success-message/styles\"]]]]]],[10,\"role\",\"presentation\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"role\",\"presentation\"],[11,\"class\",[28,\"grid\",[\"container\"],null]],[8],[0,\"\\n\"],[4,\"let\",[[28,\"transform-contentful\",[[23,3,[\"button\"]]],null],[28,\"transform-contentful-image\",[[23,3,[\"imgSrc\"]]],null]],null,{\"statements\":[[0,\"      \"],[5,\"success-message\",[],[[\"@title\",\"@description\",\"@button\",\"@imgSrc\"],[[23,3,[\"title\"]],[23,3,[\"description\"]],[28,\"hash\",null,[[\"text\",\"href\"],[[23,1,[\"text\"]],[23,1,[\"url\"]]]]],[28,\"hash\",null,[[\"url\",\"altText\"],[[23,2,[\"url\"]],[23,2,[\"altText\"]]]]]]]],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/success-message/template.hbs"
    }
  });

  _exports.default = _default;
});