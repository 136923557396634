define("cms-frontend/utils/sentry-error-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.filterFactory = filterFactory;

  function filterFactory(patterns) {
    return function (event, hint) {
      var exception = hint.originalException;
      var filterOut = patterns.some(function (pattern) {
        return exception && exception.message && exception.message.match(pattern);
      });
      return filterOut ? null : event;
    };
  }
});