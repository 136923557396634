define("cms-frontend/components/wrapper-components/responsive-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gcPX7Yhj",
    "block": "{\"symbols\":[\"@module\"],\"statements\":[[5,\"responsive-image\",[],[[\"@defaultImage\",\"@defaultImageMaxWidth\",\"@desktopImage\",\"@desktopImageMaxWidth\"],[[28,\"if\",[[23,1,[\"defaultImage\"]],[28,\"transform-contentful-media\",[[23,1,[\"defaultImage\"]]],null]],null],[23,1,[\"defaultImageMaxWidth\"]],[28,\"if\",[[23,1,[\"desktopImage\"]],[28,\"transform-contentful-media\",[[23,1,[\"desktopImage\"]]],null]],null],[23,1,[\"desktopImageMaxWidth\"]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/responsive-image/template.hbs"
    }
  });

  _exports.default = _default;
});