define("cms-frontend/components/wrapper-components/rich-text/table/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "cta-secondary-border": "#DADDEC",
    "table-wrapper": "_table-wrapper_gg92qa"
  };
  _exports.default = _default;
});