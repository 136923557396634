define("cms-frontend/components/wrapper-components/rich-text/anchor-headline/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "cta-secondary-border": "#DADDEC",
    "cta-primary": "#2B6CDE",
    "c-title": "rgba(32, 49, 81, 1)",
    "font-medium": "'CeraRoundPro-Medium'",
    "h3": "_h3_16bl1i _heading_16bl1i",
    "h4": "_h4_16bl1i _heading_16bl1i",
    "anchor-link-container": "_anchor-link-container_1ac3pl",
    "anchor-link-list": "_anchor-link-list_1ac3pl",
    "anchor-link-list-link": "_anchor-link-list-link_1ac3pl _h4_16bl1i _heading_16bl1i",
    "anchor-link-title": "_anchor-link-title_1ac3pl _h3_16bl1i _heading_16bl1i"
  };
  _exports.default = _default;
});