define("cms-frontend/components/wrapper-components/picture-frame/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5YFrSFyw",
    "block": "{\"symbols\":[\"imgSrc1\",\"imgSrc2\",\"imgSrc3\",\"@module\"],\"statements\":[[4,\"let\",[[28,\"transform-contentful-media\",[[23,4,[\"imgSrc1\"]]],null],[28,\"transform-contentful-media\",[[23,4,[\"imgSrc2\"]]],null],[28,\"transform-contentful-media\",[[23,4,[\"imgSrc3\"]]],null]],null,{\"statements\":[[0,\"  \"],[5,\"picture-frame\",[],[[\"@imgSrc1\",\"@imgSrc2\",\"@imgSrc3\"],[[23,1,[]],[23,2,[]],[23,3,[]]]]],[0,\"\\n\"]],\"parameters\":[1,2,3]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/picture-frame/template.hbs"
    }
  });

  _exports.default = _default;
});