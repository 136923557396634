define("cms-frontend/content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mIWZiu5C",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[5,\"layout\",[[12,\"data-test-content-route\",\"\"]],[[\"@modules\",\"@sectionContainerClass\",\"@modals\"],[[23,1,[\"modules\"]],[28,\"local-class\",[\"section-wrapper-container\"],[[\"from\"],[\"cms-frontend/content/styles\"]]],[23,1,[\"modals\"]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cms-frontend/content/template.hbs"
    }
  });

  _exports.default = _default;
});