define("cms-frontend/components/wrapper-components/contact-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UeWBgnAO",
    "block": "{\"symbols\":[\"avatar\",\"button\",\"@module\"],\"statements\":[[4,\"let\",[[28,\"transform-contentful-image\",[[23,3,[\"avatar\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"contact-box-wrapper\"],[[\"from\"],[\"cms-frontend/components/wrapper-components/contact-box/styles\"]]]]]],[8],[0,\"\\n    \"],[5,\"contact-box\",[],[[\"@avatar\",\"@title\",\"@description\"],[[23,1,[]],[23,3,[\"heading\"]],[23,3,[\"description\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"let\",[[28,\"transform-contentful\",[[23,3,[\"leadGenButton\"]]],null]],null,{\"statements\":[[0,\"        \"],[5,\"lead-gen-cta\",[],[[\"@text\"],[[23,2,[\"text\"]]]]],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/contact-box/template.hbs"
    }
  });

  _exports.default = _default;
});