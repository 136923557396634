define("cms-frontend/helpers/icon/validate-args", ["exports", "@clark/cms-ui/helpers/icon/validate-args"], function (_exports, _validateArgs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _validateArgs.default;
    }
  });
  Object.defineProperty(_exports, "iconValidateArgs", {
    enumerable: true,
    get: function get() {
      return _validateArgs.iconValidateArgs;
    }
  });
});