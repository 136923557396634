define("cms-frontend/components/wrapper-components/article-summary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lIxIh4Wf",
    "block": "{\"symbols\":[\"@module\"],\"statements\":[[5,\"article-summary\",[],[[\"@title\",\"@description\",\"@summaryItems\"],[[23,1,[\"title\"]],[28,\"transform-contentful-rich-text\",[[23,1,[\"description\"]]],null],[28,\"if\",[[23,1,[\"summaryItems\"]],[28,\"transform-contentful\",[[23,1,[\"summaryItems\"]]],null]],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/article-summary/template.hbs"
    }
  });

  _exports.default = _default;
});