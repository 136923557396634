define("cms-frontend/components/wrapper-components/rich-text/table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fb+0bbY1",
    "block": "{\"symbols\":[\"@module\"],\"statements\":[[1,[28,\"markdown-to-html\",[[23,1,[\"table\"]]],[[\"tables\",\"class\"],[true,[28,\"concat\",[[28,\"local-class\",[\"table-wrapper\"],[[\"from\"],[\"cms-frontend/components/wrapper-components/rich-text/table/styles\"]]]],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/rich-text/table/template.hbs"
    }
  });

  _exports.default = _default;
});