define("cms-frontend/components/wrapper-components/faq/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tWDUus0V",
    "block": "{\"symbols\":[\"header\",\"items\",\"@module\"],\"statements\":[[4,\"let\",[[28,\"transform-contentful\",[[23,3,[\"header\"]]],null],[28,\"transform-contentful\",[[23,3,[\"items\"]]],null]],null,{\"statements\":[[0,\"  \"],[5,\"faq\",[],[[\"@header\",\"@items\"],[[28,\"hash\",null,[[\"title\"],[[23,1,[\"heading\"]]]]],[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/faq/template.hbs"
    }
  });

  _exports.default = _default;
});