define("cms-frontend/components/preview/not-found-screen/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sTS/49om",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"not-found-screen\"],[[\"from\"],[\"cms-frontend/components/preview/not-found-screen/styles\"]]]]]],[10,\"data-test-not-found-screen\",\"\"],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"layout-container\"],[[\"from\"],[\"cms-frontend/components/preview/not-found-screen/styles\"]]]]]],[8],[0,\"\\n    \"],[7,\"h3\",true],[11,\"class\",[29,[[28,\"local-class\",[\"message\"],[[\"from\"],[\"cms-frontend/components/preview/not-found-screen/styles\"]]]]]],[10,\"data-test-not-found-screen-message\",\"\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"preview.not-found-screen.message\"],null],false],[0,\" 🤷🏻‍♂️\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cms-frontend/components/preview/not-found-screen/template.hbs"
    }
  });

  _exports.default = _default;
});