define("cms-frontend/components/wrapper-components/next-steps/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "arRPa2hx",
    "block": "{\"symbols\":[\"@module\"],\"statements\":[[5,\"next-steps\",[],[[\"@items\"],[[28,\"if\",[[23,1,[\"items\"]],[28,\"transform-contentful\",[[23,1,[\"items\"]]],null]],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/next-steps/template.hbs"
    }
  });

  _exports.default = _default;
});