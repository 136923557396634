define("cms-frontend/translations/en-us", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "404": {
      "cta": "zur Startseite",
      "title": "Sorry! Wir konnten die gesuchte Seite nicht finden."
    },
    "breadcrumbs": {
      "accessibility_label": "Breadcrumbs",
      "root_label": "Clark"
    },
    "button": {
      "spinner_accessibility_label": "Loading"
    },
    "carousel": {
      "navigation": {
        "next": "Next slide",
        "previous": "Previous slide"
      }
    },
    "common": {
      "close": "Close",
      "new_window_accessibility_label": "(opens a new window)"
    },
    "cookie_banner": {
      "description": "Wir verwenden Cookies, um Clark-Nutzern die bestmögliche Browsing-Erfahrung zu bieten. Wähle ”Akzeptieren”, um alle gewählten Arten von Cookies zu akzeptieren.",
      "description_collapsed": "Wir verwenden Cookies, um Clark-Nutzern die bestmögliche Browsing-Erfahrung zu bieten. Die gesammelten Daten werden zur Optimierung der Website und App genutzt, um deine Erfahrung zu optimieren und personalisieren. Die Daten werden im Rahmen unserer EU-weiten Tätigkeit genutzt. Wähle ”Akzeptieren”, um alle Cookies zu akzeptieren.",
      "error": "Bei der Verarbeitung Ihrer Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
      "header": "Wir verwenden Cookies",
      "more_info_link": "/datenschutz",
      "more_info_text": "Mehr erfahren",
      "primary_cta": "Akzeptieren",
      "secondary_cta": "Konfigurieren",
      "tracking": {
        "analytics": {
          "description": "Diese Cookies sind notwendig, um dir eine sichere und funktionale Nutzung von Clark zu ermöglichen. Du kannst sie nicht de-aktivieren.",
          "header": "Funktionale Cookies"
        },
        "marketing": {
          "description": "Diese Cookies helfen uns zu verstehen, wie effektiv unsere Marketingkampagnen sind und helfen uns, Clark für dich zu optimieren. Wir übermitteln dazu pseudonymisierte Daten an unsere Marketingpartner.",
          "header": "Tracking & Marketing"
        }
      }
    },
    "lead_gen": {
      "terms_and_condition": "Ich bin damit einverstanden, dass CLARK Germany mich per E-mail und zur Klärung von Rückfragen telefonisch kontaktiert. Es gelten unsere Bestimmungen für den Datenschutz."
    },
    "modal": {
      "spinner_accessibility_label": "Loading"
    },
    "name": "Clark",
    "next-steps": {
      "title": "Next Steps"
    },
    "partners": {
      "partner_of": "Partner of",
      "partner_of_label": "Partner of {name}"
    },
    "payback": {
      "total": "Total: {total} points"
    },
    "preview": {
      "error-screen": {
        "hint": "Make sure you're using a valid ID of existing entry.",
        "message": "Couldn't render a preview for the requested entry"
      },
      "not-found-screen": {
        "message": "Oops! Sorry, nothing to preview here"
      }
    },
    "skepticsSlider": {
      "next": "Next",
      "play": "Open video for {name}"
    },
    "slideShow": {
      "navigation": {
        "control": "Go to slide {slideNumber}"
      }
    }
  };
  _exports.default = _default;
});