define("cms-frontend/components/wrapper-components/hero-small/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kwRjVcZu",
    "block": "{\"symbols\":[\"@module\"],\"statements\":[[5,\"hero-small\",[],[[\"@title\",\"@description\",\"@imgSrc\"],[[23,1,[\"heading\"]],[23,1,[\"description\"]],[28,\"transform-contentful-media\",[[23,1,[\"imgSrc\"]]],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/hero-small/template.hbs"
    }
  });

  _exports.default = _default;
});