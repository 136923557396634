define("cms-frontend/components/wrapper-components/textbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mX8ShpYa",
    "block": "{\"symbols\":[\"header\",\"items\",\"footer\",\"@module\"],\"statements\":[[4,\"let\",[[28,\"transform-contentful\",[[23,4,[\"header\"]]],null],[28,\"transform-contentful\",[[23,4,[\"items\"]]],null],[28,\"if\",[[23,4,[\"footer\"]],[28,\"transform-contentful\",[[23,4,[\"footer\"]]],null],null],null]],null,{\"statements\":[[0,\"  \"],[5,\"textbox\",[],[[\"@header\",\"@items\",\"@footer\"],[[28,\"hash\",null,[[\"title\"],[[23,1,[\"heading\"]]]]],[23,2,[]],[28,\"hash\",null,[[\"mobile\",\"desktop\"],[[28,\"hash\",null,[[\"text\",\"href\"],[[23,3,[\"mobileText\"]],[23,3,[\"mobileLink\"]]]]],[28,\"hash\",null,[[\"text\",\"href\"],[[23,3,[\"desktopText\"]],[23,3,[\"desktopLink\"]]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[1,2,3]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/textbox/template.hbs"
    }
  });

  _exports.default = _default;
});