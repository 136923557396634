define("cms-frontend/components/preview/error-screen/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pWJFp7m1",
    "block": "{\"symbols\":[\"@entryId\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"error-screen\"],[[\"from\"],[\"cms-frontend/components/preview/error-screen/styles\"]]]]]],[10,\"data-test-error-screen\",\"\"],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"layout-container\"],[[\"from\"],[\"cms-frontend/components/preview/error-screen/styles\"]]]]]],[8],[0,\"\\n    \"],[7,\"h3\",true],[11,\"class\",[29,[[28,\"local-class\",[\"message\"],[[\"from\"],[\"cms-frontend/components/preview/error-screen/styles\"]]]]]],[10,\"data-test-error-screen-message\",\"\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"preview.error-screen.message\"],null],false],[0,\"\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"        \"],[7,\"strong\",true],[10,\"data-test-error-screen-entry-id\",\"\"],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\\n    \"],[7,\"p\",true],[11,\"class\",[29,[[28,\"local-class\",[\"hint\"],[[\"from\"],[\"cms-frontend/components/preview/error-screen/styles\"]]]]]],[10,\"data-test-error-screen-hint\",\"\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"preview.error-screen.hint\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cms-frontend/components/preview/error-screen/template.hbs"
    }
  });

  _exports.default = _default;
});