define("cms-frontend/helpers/localize-url", ["exports", "@clark/cms-ui/helpers/localize-url"], function (_exports, _localizeUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _localizeUrl.default;
    }
  });
  Object.defineProperty(_exports, "localizeUrl", {
    enumerable: true,
    get: function get() {
      return _localizeUrl.localizeUrl;
    }
  });
});