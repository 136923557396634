define("cms-frontend/helpers/transform-contentful-rich-text", ["exports", "@contentful/rich-text-html-renderer", "@contentful/rich-text-types", "cms-frontend/components/wrapper-components/rich-text/styles", "cms-frontend/utils/rich-text-header"], function (_exports, _richTextHtmlRenderer, _richTextTypes, _styles, _richTextHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function transformContentfulRichText(parameters) {
    var _renderNode;

    var _parameters = _slicedToArray(parameters, 1),
        node = _parameters[0];

    if (!node) return undefined;
    var options = {
      renderNode: (_renderNode = {}, _defineProperty(_renderNode, _richTextTypes.BLOCKS.HEADING_2, function (headerNode, next) {
        return "<h2 id='".concat((0, _richTextHeader.default)(headerNode), "' class=\"").concat(_styles.default['rich-text-header-anchor'], "\" >").concat(next(headerNode.content), "</h2>");
      }), _defineProperty(_renderNode, _richTextTypes.BLOCKS.UL_LIST, function (unorderedListNode, next) {
        return "<ul class=".concat(_styles.default['rich-text-unordered-list'], ">").concat(next(unorderedListNode.content), "</ul>");
      }), _defineProperty(_renderNode, _richTextTypes.BLOCKS.PARAGRAPH, function (paragraphNode, next) {
        var style = paragraphNode.content[0].value === '' ? "class=".concat(_styles.default['empty-paragraph']) : '';
        return "<p ".concat(style, ">").concat(next(paragraphNode.content), "</p>");
      }), _defineProperty(_renderNode, _richTextTypes.BLOCKS.HR, function () {
        return "<hr class='".concat(_styles.default['line-breaker'], "'/>");
      }), _renderNode)
    };

    if (node.nodeType !== 'document') {
      return (0, _richTextHtmlRenderer.documentToHtmlString)({
        data: {},
        content: [node],
        nodeType: _richTextTypes.BLOCKS.DOCUMENT
      }, options);
    }

    return Ember.String.htmlSafe((0, _richTextHtmlRenderer.documentToHtmlString)(node, options));
  }

  var _default = Ember.Helper.helper(transformContentfulRichText);

  _exports.default = _default;
});