define("cms-frontend/modifiers/lock-scroll", ["exports", "@clark/cms-ui/modifiers/lock-scroll"], function (_exports, _lockScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _lockScroll.default;
    }
  });
});