define("cms-frontend/components/cookie-banner/tracking-option/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L2aeb1Y3",
    "block": "{\"symbols\":[\"&attrs\",\"@header\",\"@description\",\"@isChecked\",\"@isDisabled\",\"@onClick\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"cookie-banner-tracking\"],[[\"from\"],[\"cms-frontend/components/cookie-banner/tracking-option/styles\"]]]]]],[13,1],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"inner\"],[[\"from\"],[\"cms-frontend/components/cookie-banner/tracking-option/styles\"]]]]]],[8],[0,\"\\n    \"],[7,\"p\",true],[11,\"class\",[29,[[28,\"local-class\",[\"header\"],[[\"from\"],[\"cms-frontend/components/cookie-banner/tracking-option/styles\"]]]]]],[10,\"data-test-tracking-header\",\"\"],[8],[0,\"\\n      \"],[1,[23,2,[]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[11,\"class\",[29,[[28,\"local-class\",[\"description\"],[[\"from\"],[\"cms-frontend/components/cookie-banner/tracking-option/styles\"]]]]]],[10,\"data-test-tracking-description\",\"\"],[8],[0,\"\\n      \"],[1,[23,3,[]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[5,\"form-builder/input-field/toggle-button\",[],[[\"@isChecked\",\"@isDisabled\",\"@onClick\"],[[23,4,[]],[23,5,[]],[23,6,[]]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cms-frontend/components/cookie-banner/tracking-option/template.hbs"
    }
  });

  _exports.default = _default;
});