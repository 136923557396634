define("cms-frontend/components/wrapper-components/media-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "THppESSx",
    "block": "{\"symbols\":[\"footer\",\"@module\"],\"statements\":[[5,\"media-box\",[],[[\"@imgSource\",\"@title\",\"@description\",\"@orientation\"],[[28,\"transform-contentful-media\",[[23,2,[\"imgSrc\"]]],null],[23,2,[\"heading\"]],[23,2,[\"description\"]],[23,2,[\"orientation\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,2,[\"footerContent\"]]],null,{\"statements\":[[4,\"each\",[[28,\"transform-contentful\",[[23,2,[\"footerContent\"]]],null]],null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[\"componentType\"]],\"switchCtaComponent\"],null]],null,{\"statements\":[[0,\"        \"],[5,\"button\",[],[[\"@isPrimary\",\"@url\"],[[28,\"not\",[[23,1,[\"isSecondary\"]]],null],[28,\"if\",[[28,\"is-mobile\",[\"any\"],null],[23,1,[\"mobileLink\"]],[23,1,[\"desktopLink\"]]],null]]],{\"statements\":[[0,\"\\n          \"],[1,[28,\"if\",[[28,\"is-mobile\",[\"any\"],null],[23,1,[\"mobileText\"]],[23,1,[\"desktopText\"]]],null],false],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[\"componentType\"]],\"appStore\"],null]],null,{\"statements\":[[0,\"        \"],[5,\"common/app-store\",[],[[\"@appStoreLink\",\"@playStoreLink\"],[[23,1,[\"appStoreLink\"]],[23,1,[\"playStoreLink\"]]]]],[0,\"\\n      \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/media-box/template.hbs"
    }
  });

  _exports.default = _default;
});