define("cms-frontend/components/wrapper-components/crosslink/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZzkFb8pU",
    "block": "{\"symbols\":[\"model\",\"@module\"],\"statements\":[[4,\"let\",[[28,\"hash\",null,[[\"header\",\"items\"],[[28,\"transform-contentful\",[[23,2,[\"header\"]]],null],[28,\"transform-contentful\",[[23,2,[\"items\"]]],null]]]]],null,{\"statements\":[[0,\"  \"],[5,\"crosslink\",[],[[\"@header\",\"@items\"],[[28,\"hash\",null,[[\"title\"],[[23,1,[\"header\",\"heading\"]]]]],[23,1,[\"items\"]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/crosslink/template.hbs"
    }
  });

  _exports.default = _default;
});