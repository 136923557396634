define("cms-frontend/components/wrapper-components/two-column/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZkdabcbZ",
    "block": "{\"symbols\":[\"header\",\"footer\",\"@module\"],\"statements\":[[4,\"let\",[[28,\"transform-contentful\",[[23,3,[\"header\"]]],null],[28,\"transform-contentful\",[[23,3,[\"footer\"]]],null]],null,{\"statements\":[[0,\"  \"],[5,\"two-column\",[],[[\"@header\",\"@content\",\"@footer\"],[[28,\"hash\",null,[[\"title\",\"description\"],[[23,1,[\"heading\"]],[23,1,[\"description\"]]]]],[23,3,[\"content\"]],[28,\"hash\",null,[[\"mobile\",\"desktop\",\"isSecondary\"],[[28,\"hash\",null,[[\"text\",\"href\"],[[23,2,[\"mobileText\"]],[23,2,[\"mobileLink\"]]]]],[28,\"hash\",null,[[\"text\",\"href\"],[[23,2,[\"desktopText\"]],[23,2,[\"desktopLink\"]]]]],[23,2,[\"isSecondary\"]]]]]]]],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/two-column/template.hbs"
    }
  });

  _exports.default = _default;
});