define("cms-frontend/interfaces/brand-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    If you find yourself changing this file
    then please run:
  
    yarn generate-config-docs
  
    to make a new mark down file that can be updated on confluence
    at the following location:
  
    https://clarkteam.atlassian.net/wiki/spaces/JCLARK/pages/1833730185/CMS-Frontend+Configuration
  
    the contents can just be swapped out for the generated markdown named
    brand-config-docs.md
  
    also please keep this up to date with how you use the config file in the fe
    this is not ideal but more reliable than confluence.
  */
  var _default = BrandConfig;
  _exports.default = _default;
});