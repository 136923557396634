define("cms-frontend/utils/rich-text-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = generateHeaderId;

  function generateHeaderId(node) {
    var content = node.content.reduce(function (totalContent, currentNode) {
      return totalContent + currentNode.value;
    }, '');
    return content.split(' ').join('-').trim();
  }
});