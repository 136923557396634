define("cms-frontend/breakpoints", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.mediaQueries = _exports.breakpoints = void 0;
  // FIXME: Extract to a separate package to avoid repetition:
  // https://clarkteam.atlassian.net/browse/JCLARK-60691
  // Breakpoints
  var breakpoints = {
    xsmall: 0,
    small: 400,
    medium: 769,
    large: 1200
  }; // Media queries
  // FIXME: Remove some repetition and iterate
  // over `breakpoints` object keys instead

  _exports.breakpoints = breakpoints;
  var mediaQueries = {
    beforeSmall: "only screen and (max-width: ".concat(breakpoints.small - 0.02, "px)"),
    fromSmall: "only screen and (min-width: ".concat(breakpoints.small, "px)"),
    smallOnly: "only screen and (min-width: ".concat(breakpoints.small, "px) and (max-width: ").concat(breakpoints.medium - 0.02, "px)"),
    beforeMedium: "only screen and (max-width: ".concat(breakpoints.medium - 0.02, "px)"),
    fromMedium: "only screen and (min-width: ".concat(breakpoints.medium, "px)"),
    mediumOnly: "only screen and (min-width: ".concat(breakpoints.medium, "px) and (max-width: ").concat(breakpoints.large - 0.02, "px)"),
    beforeLarge: "only screen and (max-width: ".concat(breakpoints.large - 0.02, "px)"),
    fromLarge: "only screen and (min-width: ".concat(breakpoints.large, "px)"),
    largeOnly: "only screen and (min-width: ".concat(breakpoints.large, "px)"),
    // Some aliases
    beforeDesktop: "only screen and (max-width: ".concat(breakpoints.large - 0.02, "px)"),
    desktop: "only screen and (min-width: ".concat(breakpoints.large, "px)")
  };
  _exports.mediaQueries = mediaQueries;
  var _default = mediaQueries;
  _exports.default = _default;
});