define("cms-frontend/components/lead-gen-cta/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RgM7jR+s",
    "block": "{\"symbols\":[\"@text\"],\"statements\":[[5,\"button\",[[12,\"class\",[29,[[28,\"local-class\",[\"lead-gen\"],[[\"from\"],[\"cms-frontend/components/lead-gen-cta/styles\"]]]]]]],[[\"@isPrimary\",\"@onClick\"],[true,[28,\"fn\",[[28,\"mut\",[[23,0,[\"modal\",\"leadGen\"]]],null],true],null]]],{\"statements\":[[0,\"\\n  \"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cms-frontend/components/lead-gen-cta/template.hbs"
    }
  });

  _exports.default = _default;
});