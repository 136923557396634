define("cms-frontend/components/cookie-banner/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "brand-secondary": "#17203D",
    "cta-primary": "#2B6CDE",
    "cta-secondary-border": "#DADDEC",
    "c-title": "rgba(32, 49, 81, 1)",
    "up-to-medium": "only screen and (max-width: calc(769px - 1px))",
    "from-medium": "only screen and (min-width: 769px)",
    "font-medium": "'CeraRoundPro-Medium'",
    "z-index-modal": "519",
    "cookie-banner-container": "_cookie-banner-container_1d8ppf",
    "cookie-banner-inner": "_cookie-banner-inner_1d8ppf",
    "header": "_header_1d8ppf",
    "header-label": "_header-label_1d8ppf",
    "description": "_description_1d8ppf",
    "close-button": "_close-button_1d8ppf",
    "more-information": "_more-information_1d8ppf",
    "cookie-banner-collapse": "_cookie-banner-collapse_1d8ppf",
    "cookie-banner-ctas": "_cookie-banner-ctas_1d8ppf",
    "configure": "_configure_1d8ppf",
    "icon": "_icon_1d8ppf",
    "inverted": "_inverted_1d8ppf",
    "cookie-banner-opt-in": "_cookie-banner-opt-in_1d8ppf"
  };
  _exports.default = _default;
});