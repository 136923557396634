define("cms-frontend/utils/serialization", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deserializeModel = deserializeModel;
  _exports.deserializeImageModel = deserializeImageModel;

  function deserializeModel(model) {
    (false && !(Boolean(model)) && Ember.assert('deserializeModel: Missing argument model', Boolean(model)));
    (false && !(Boolean(model.sys && model.fields)) && Ember.assert('deserializeModel: model is not a valid Contentful Entry (should have `sys` and `fields` props)', Boolean(model.sys && model.fields)));
    return model.fields;
  }

  function deserializeImageModel(model) {
    var fields = deserializeModel(model);
    return {
      url: fields.file.url,
      altText: fields.description
    };
  }
});