define("cms-frontend/404/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3ovSmxMw",
    "block": "{\"symbols\":[],\"statements\":[[5,\"cms-header\",[],[[\"@navigation\"],[[23,0,[\"model\",\"menuItem\"]]]]],[0,\"\\n\"],[7,\"section\",true],[11,\"class\",[29,[[28,\"grid\",[\"container\"],null],\" \",[28,\"local-class\",[\"page-404-container\"],[[\"from\"],[\"cms-frontend/404/styles\"]]]]]],[8],[0,\"\\n  \"],[5,\"icon\",[[12,\"class\",[29,[[28,\"local-class\",[\"page-404-image\"],[[\"from\"],[\"cms-frontend/404/styles\"]]]]]]],[[\"@source\"],[\"404\"]]],[0,\"\\n  \"],[7,\"h2\",true],[11,\"class\",[29,[[28,\"typo\",[\"h2\"],null],\" \",[28,\"local-class\",[\"page-404-title\"],[[\"from\"],[\"cms-frontend/404/styles\"]]]]]],[8],[0,\"\\n    \"],[1,[28,\"markdown-to-html\",[[28,\"t\",[\"404.title\"],null]],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[5,\"button\",[],[[\"@url\"],[\"/\"]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"t\",[\"404.cta\"],null],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"],[5,\"cms-footer\",[],[[],[]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cms-frontend/404/template.hbs"
    }
  });

  _exports.default = _default;
});