define("cms-frontend/components/wrapper-components/menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QEBwZXnI",
    "block": "{\"symbols\":[\"@module\"],\"statements\":[[5,\"cms-header\",[],[[\"@navigation\"],[[23,1,[\"menuItem\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/menu/template.hbs"
    }
  });

  _exports.default = _default;
});